<template>
    <div class="stats-table relative overflow-x-auto">
        <table v-if="players" class="w-full">
            <thead>
            <tr>
                <th>
                    Name
                </th>
                <th>
                    Team
                </th>
                <th>
                    New Team
                </th>
                <th>
                    Position
                </th>
                <th>
                    New Position
                </th>
                <th>
                    Value
                </th>
                <th>
                    New Value
                </th>
                <th>
                    Selected %
                </th>
                <th>
                    Points
                </th>
                <th>
                    Appearances
                </th>
                <th>
                    Starts
                </th>
                <th>
                    Minutes
                </th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="player in players">
                    <th>
                        {{ player.name }}
                    </th>
                    <td>
                        {{ teamOptions[player.team_id] }}
                    </td>
                    <td>
                        <select
                            v-model="teamSelectValues[player.id]"
                            @change="updateTeam(player, $event.target.value)"
                        >
                            <option value="left_league">Left League</option>
                            <option v-for="(teamOptionName, teamOptionId) in teamOptions" :value="teamOptionId">{{ teamOptionName }}</option>
                        </select>
                    </td>
                    <td>
                        {{ player.position }}
                    </td>
                    <td>
                        <player-position-input
                            :auto-save="false"
                            :player="player"
                            :custom-value="submissions.hasOwnProperty(player.id) ? submissions[player.id].position : null"
                            :force-editable="true"
                            @change="updatePosition(player, $event)"
                        ></player-position-input>
                    </td>
                    <td>
                        {{ player.pretty_value }}
                    </td>
                    <td>
                        <player-value-input
                            class="inline-block"
                            :auto-save="false"
                            :player="player"
                            :custom-value="submissions.hasOwnProperty(player.id) ? submissions[player.id].value : null"
                            :force-editable="true"
                            @input="updateValue(player, $event)"
                        ></player-value-input>
                    </td>
                    <td>
                        {{ player.selected_by_percent ? player.selected_by_percent.toFixed(2) : 0 }}%
                    </td>
                    <td>
                        {{ player.points }}
                    </td>
                    <td>
                        {{ player.appearances }}
                    </td>
                    <td>
                        {{ player.starts }}
                    </td>
                    <td>
                        {{ player.minutes }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import {useFlashStore} from "@/stores/flashStore";
import PlayerPositionInput from "@/components/PlayerPositionInput.vue";
import PlayerValueInput from "@/components/PlayerValueInput.vue";
import UserSubmissionsQuery from "@/queries/userSubmissionsQuery";
import {useDefaultStore} from "@/stores/defaultStore";

export default {
    components: {PlayerValueInput, PlayerPositionInput},
    emits: ['submissionCreated', 'error'],
    props: {
        players: {
            type: [Object, Array],
            required: true
        }
    },
    setup(props, {emit}) {
        const store = useDefaultStore()
        const seasonStore = useSeasonStore()
        const flashStore = useFlashStore()

        onMounted(() => {
            if (store.authUser) {
                UserSubmissionsQuery.get(seasonStore.season.id, store.authUser.id)
                    .then(response => {
                        submissions.value = _.keyBy(response.data, 'player_season_id')
                    })
                    .catch(error => {
                        flashStore.axiosError(error, 'Error fetching existing submissions')
                    })
            }
        })

        const submissions = ref({})

        const teamOptions = computed(() => {
            return _.pluck(_.sortBy(seasonStore.teams, 'name'), 'name', 'id')
        })

        const teamSelectValues = computed(() => {
            return _.mapValues(_.keyBy(props.players, 'id'), player => {
                return submissions.value.hasOwnProperty(player.id) && (submissions.value[player.id].team_id || submissions.value[player.id].left_league)
                    ? (submissions.value[player.id].left_league ? 'left_league' : submissions.value[player.id].team_id)
                    : player.team_id
            })
        })

        const updateTeam = (player, newTeam) => {
            createSubmission(player.id, {
                team_id: newTeam === 'left_league' ? null : newTeam,
                left_league: newTeam === 'left_league' ? true : false
            })
        }

        const updatePosition = (player, newPosition) => {
            createSubmission(player.id, {position: newPosition})
        }

        const updateValue = (player, newValue) => {
            createSubmission(player.id, {value: newValue})
        }

        const createSubmission = (playerSeasonId, payload) => {
            UserSubmissionsQuery.create(playerSeasonId, payload)
                .then(response => {
                    flashStore.setFlash('success', 'Submission Created!')

                    submissions.value[response.data.player_season_id] = response.data

                    emit('submissionCreated', response.data)
                })
                .catch(error => {
                    flashStore.axiosError(error, 'Error creating submission')

                    emit('error', error)
                })
        }

        return {submissions, teamOptions, teamSelectValues, updateTeam, updatePosition, updateValue}
    }
}
</script>
