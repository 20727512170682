<template>
    <template v-if="!seasons">
        Loading...
    </template>
    <template v-else-if="theme === 'mexico'" id="home-page">
        <mexico></mexico>
    </template>
    <div v-else id="home-page">
        <nav-bar></nav-bar>
        <beta-text></beta-text>
        <div id="home-body" v-if="seasons" class="p-3">
            <div v-if="seasons.hasOwnProperty('active')" class="paragraphs">
                <p>We have loads of different Fantasy Football Competitions for you to choose from across a range of countries and both Men's and Women's Football. Each one is a separate competition and you can choose to take in part in however many you choose. Each competition also has a Prediction League letting you run tipping comps with your friends to predict the results of each fixture.</p>
            </div>
            <div v-else>
                <p>All the competitions have now finished. Check back soon for the start of new fantasy competitions.</p>
            </div>

            <template v-for="(statusTitle, statusKey) in statuses">
                <div v-if="seasons.hasOwnProperty(statusKey)" class="mt-3">
                    <h1 class="mb-3">{{ statusTitle }}</h1>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        <home-page-season-box
                            v-for="season in seasons[statusKey]"
                            :key="season.id"
                            :season="season"
                            :auth-entry="authEntries[season.id]"
                        ></home-page-season-box>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {useDefaultStore} from "../stores/defaultStore";
import {computed, onMounted, ref} from "vue";
import BetaText from "../components/BetaText.vue";
import NavBar from "../components/NavBar.vue";
import Explanation from "../components/Explanation.vue";
import HomePageSeasonBox from "../components/HomePageSeasonBox.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {HomePageSeasonBox, Explanation, NavBar, BetaText},
    setup() {
        const store = useDefaultStore()

        onMounted(() => {
            getAuthEntries()
        })

        useSeoMeta({
            title: () => 'Fantasy Football Game for Portugal, Spain, Italy, Germany, France, Netherlands and many more. ' + (_.size(store.seasons)) + ' competitions and counting.',
            description: () => '%siteName is dedicated to making Fantasy Football available for more leagues in more countries.'
        })

        const authEntries = ref({})

        const theme = computed(() => store.theme )

        const getAuthEntries = async () => {
            axios
                .get('/api/entry/auth')
                .then(response => {
                    authEntries.value = response.data || {}
                })
        }

        const seasons = computed(() => {
            return {
                active: _.orderBy(_.filter(store.seasons, season => {
                        return season.status === 'active' && new Date(season.start_at) < new Date() && authEntries.value.hasOwnProperty(season.id) && authEntries.value[season.id].status === 'valid'
                    }), 'deadline_timestamp', 'asc'),
                available: _.orderBy(_.filter(store.seasons, season => {
                    return season.status === 'active' && new Date(season.start_at) < new Date() && !(authEntries.value.hasOwnProperty(season.id) && authEntries.value[season.id].status === 'valid')
                }), 'name', 'asc'),
                finished: _.orderBy(_.filter(store.seasons, {status: 'finished'}), 'end_at_timestamp', 'desc'),
                upcoming: _.orderBy(_.filter(store.seasons, season => {
                    return season.status === 'active' && new Date(season.start_at) > new Date()
                }), season => new Date(season.start_at), 'asc'),
            }

            return _.groupBy(_.orderBy(store.seasons, season => {
                return new Date(season.start_at)
            }, 'desc'), 'status')
        })

        const statuses = {
            upcoming: 'Upcoming Competitions',
            active: 'My Competitions',
            available: 'Available Competitions',
            finished: 'Finished Competitions',
        }

        return {statuses, seasons, authEntries, theme}
    }
}
</script>
