<template>
    <div v-if="isEditable">
        <select v-model="positionValue" @change="onChange" :class="{'bg-red-200': !positionOptions.includes(positionValue)}">
            <option v-for="position in positionOptions" :value="position">
                {{ position }}
            </option>
        </select>
    </div>
    <div v-else>
        {{ positionValue }}
    </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['change', 'success', 'error'],
    props: {
        player: {
            type: Object,
            required: true
        },
        autoSave: {
            type: Boolean,
            required: true
        },
        customValue: {
            type: Number,
            default: null
        },
        forceEditable: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const isEditable = computed(() => {
            return props.forceEditable
                || seasonStore.season.status !== 'active'
                || props.player.selected_by < 1
        })

        const saving = ref(false)

        const positionOptions = [
            'GK',
            'DEF',
            'MID',
            "ATT"
        ]

        const positionValue = ref(null)

        watch(
            [() => props.customValue, () => props.player.position], () => {
                positionValue.value = props.customValue || props.player.position;
            }
        )

        onMounted(() => {
            positionValue.value = props.customValue || props.player.position
        })

        const onChange = () => {
            if (props.autoSave) {
                submitForm()
            } else {
                emit('change', positionValue.value)
            }
        }


        const submitForm = () => {
            saving.value = true

            seasonStore
                .updatePlayer(props.player.id, {position: positionValue.value})
                .then(response => {
                    useFlashStore().setFlash('success', 'Position Updated!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error saving player position')
                    positionValue.value = props.player.position
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {saving, isEditable, positionOptions, positionValue, onChange, submitForm}
    }
}
</script>
