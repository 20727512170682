<template>
    <form v-if="isEditable" @submit.prevent="submitForm">
        <input type="number" step="0.1" v-model="value" style="width:60px" :disabled="saving" :class="{'bg-orange-200': !value}">
    </form>
    <input v-else type="number" v-model="value" style="width:60px" >
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['input', 'success', 'error'],
    props: {
        player: {
            type: Object,
            required: true
        },
        autoSave: {
            type: Boolean,
            required: true
        },
        customValue: {
            type: Number,
            default: undefined
        },
        forceEditable: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const isEditable = computed(() => {
            return props.forceEditable
                || seasonStore.season.status !== 'active'
                || props.player.selected_by < 1
        })

        const saving = ref(false)
        const value = ref(null)

        watch(
            [() => props.customValue, () => props.player.value], () => {
                setDefaultValue()
            }
        )

        onMounted(() => {
            setDefaultValue()
        })

        const setDefaultValue = () => {
            setValue(props.customValue === undefined ? props.player.value : props.customValue)
        }

        const setValue = (newValue) => {
            value.value = newValue ? (newValue / 10).toFixed(1) : null
        }

        const dbValue = computed(() => value.value ? value.value * 10 : value.value)

        const submitForm = () => {
            if (value.value > 20 || value.value < 1 || !/^\d+(\.\d)?$/.test(value.value.toString())) {
                useFlashStore().setFlash('error', 'Player Value must be between 1 and 20 and at most one decimal place.')
                return;
            }

            if (!props.autoSave) {
                emit('input', dbValue.value)
                return;
            }
            saving.value = true

            seasonStore
                .updatePlayer(props.player.id, {value: dbValue.value})
                .then(response => {
                    useFlashStore().setFlash('success', 'Player Value Updated!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error updating player value')
                    setDefaultValue()
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {isEditable, saving, value, submitForm}
    }
}
</script>
