<template>
    <span :data-customTooltip="tooltip">{{ fromNow }}</span>
</template>
<script>
import dayjs from "dayjs";
import {onMounted, ref} from "vue";

export default {
    props: {
        date: {
            type: String,
            required: true
        },
    },
    setup(props) {
        const fromNow = ref(null)
        const tooltip = ref(dayjs(props.date).format('dddd MMMM D, YYYY'))
        onMounted(() => {
            setInterval(() => {
                fromNow.value = dayjs(props.date).fromNow()
            }, 1000)
        })
        return { fromNow, tooltip }
    }
}
</script>
