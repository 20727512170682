<template>
    <div>
        <beta-text></beta-text>
        <div class="grid grid-cols-1 lg:grid-cols-2">
            <div>
                <fixtures :load-picks="true"></fixtures>
            </div>
            <explanation class="p-3"></explanation>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import Fixtures from "../components/Fixtures.vue";
import BetaText from "../components/BetaText.vue";
import Explanation from "../components/Explanation.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {
        Explanation,
        BetaText,
        Fixtures
    },
    setup(props) {
        const store = useSeasonStore()

        useSeoMeta({
            title: () => 'Fantasy ' + store.season.name + ' Fixtures',
            description: () => 'Fantasy ' + store.season.name + ' Fixtures'
        })

        return {}
    }
}
</script>
