<template>
    <div>
        <div class="paragraphs p-3">
            <p>Welcome to the contribute section! Here you can recommend how players prices are set for the new season.</p>
            <p>I'm currently running Fantasy for more than 50 leagues each year and obviously it's impossible for me to set the prices perfectly for each league, but how well the prices can really define how fun the season is.</p>
            <p>So now you have the opportunity to help by suggesting the correct price and position for each player and I'll use these to help set the prices. I'm also looking for long term moderators who can help manage each league and will reach out to people who contribute using this form.</p>
            <p>So get involved and make this the best Fantasy possible!</p>
        </div>
        <div class="flex items-center">
            <player-filter
                v-model="filter"
            ></player-filter>
            <button class="btn" @click.prevent="resetFilter">
                Clear
            </button>
        </div>
        <season-user-submissions-table
            :players="players"
        ></season-user-submissions-table>
    </div>
</template>

<script>
import {ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import PlayerFilter from "@/components/PlayerFilter.vue";
import {useSeoMeta} from "@unhead/vue";
import SeasonUserSubmissionsTable from "@/components/SeasonUserSubmissionsTable.vue";

export default {
    components: {SeasonUserSubmissionsTable, PlayerFilter},
    setup() {
        const seasonStore = useSeasonStore();

        useSeoMeta({
            title: () => 'Contribute | ' + seasonStore.season.name,
            description: () => 'Contribute Season ' + seasonStore.season.name
        })

        const players = computed(() => {
            return seasonStore.filterPlayers(filter.value)
        })

        const resetFilter = () => {
            filter.value = {
                team_id: 'ALL',
                value: 'ALL',
                position: 'ALL',
            }
        }

        const filter = ref({
            team_id: 'ALL',
            value: 'ALL',
            position: 'ALL',
            sort: 'team',
            sort_dir: 'asc',
            status: 'active'
        })

        return {players, resetFilter, filter}
    }
}
</script>
