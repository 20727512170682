<template>
    <div v-if="gameweeks" id="status-page">
        <div v-if="duplicateFixtures && duplicateFixtures.length > 0" class="box red-gradient">
            <div v-for="duplicateFixture in duplicateFixtures" class="p-3">
                {{ duplicateFixture.team_name }} has multiple fixtures on {{ duplicateFixture.date }} in {{ duplicateFixture.season_name }}
            </div>
        </div>
        <div v-if="smallGameweeks && smallGameweeks.length > 0" class="box red-gradient">
            <div v-for="smallGameweek in smallGameweeks" class="p-3">
                GW {{ smallGameweek.number }} in <router-link :to="{name: 'mod-gameweeks', params:{seasonId:smallGameweek.season_id}}" class="underline">{{ seasons[smallGameweek.season_id].name }}</router-link> only has {{ smallGameweek.fixtures_count }} fixtures starting <date-from-now :date="smallGameweek.open_at"></date-from-now>
            </div>
        </div>
        <div v-if="modStatusFixtures && modStatusFixtures.length > 0" class="box red-gradient">
            <div class="p-3">
                The following fixtures need checking by a mod
            </div>
            <div v-for="modStatusFixture in modStatusFixtures" class="p-3">
                ({{ modStatusFixture.mod_status }}) - {{ modStatusFixture.description }} - <router-link :to="{name: 'mod-fixtures', params:{seasonId:modStatusFixture.season_id}}" class="underline">{{ seasons[modStatusFixture.season_id].name }}</router-link>  - <date-from-now :date="modStatusFixture.kickoff"></date-from-now>
            </div>
        </div>
        <div v-if="gameweeksNoAutoSubs && gameweeksNoAutoSubs.length > 0" class="box red-gradient">
            <div v-for="noAutoSub in gameweeksNoAutoSubs" class="p-3">
                GW {{ noAutoSub.number }} in <router-link :to="{name: 'mod-gameweeks', params:{seasonId:noAutoSub.season_id}}" class="underline">{{ seasons[noAutoSub.season_id].name }}</router-link> has not run auto subs. Should have run  <date-from-now :date="noAutoSub.auto_sub_at"></date-from-now>
            </div>
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-2">
            <div v-for="season in seasons" :key="season.id" class="mb-3 mx-3 box blue-gradient">
                <h4 class="flex items-center">
                    <img v-if="season.flag_path" :src="season.flag_path" class="mr-3" />
                    <router-link :to="{name:'mod-gameweeks', params:{seasonId:season.id}}">
                        {{ season.name }}
                    </router-link>
                </h4>
                <div v-if="gameweeks.hasOwnProperty(season.id)" class="stats-table">
                    <table class="table-auto">
                        <thead>
                            <tr>
                                <th>GW</th>
                                <th>Open</th>
                                <th>Lock</th>
                                <th>Fixtures</th>
                                <th>Blank</th>
                                <th>Double</th>
                                <th>Allowed</th>
                                <th>Subs</th>
                                <th>Entries</th>
                                <th>Active</th>
                                <th>Transfers</th>
                                <th>Chips</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="gameweek in gameweeks[season.id]">
                                <th>
                                    {{ gameweek.number }}
                                </th>
                                <td :class="gameweek.open_at_timestamp > nowTimestamp ? 'success-text' : 'danger-text'">
                                    {{ gameweek.open_at_text }}
                                </td>
                                <td :class="gameweek.locked_at_timestamp > nowTimestamp ? 'success-text' : 'danger-text'">
                                    {{ gameweek.locked_at_text }}
                                </td>
                                <td>
                                    {{ gameweek.fixtures_count }}
                                </td>
                                <td>
                                    {{ gameweek.blanks_count }}
                                </td>
                                <td>
                                    {{ gameweek.doubles_count }}
                                </td>
                                <td>
                                    <i v-if="gameweek.free_transfers === null" class="fa-solid fa-infinity"></i>
                                    <span v-else>{{ gameweek.free_transfers }}</span>
                                </td>
                                <td>
                                    <i v-if="gameweek.calculated_auto_subs" class="fa-solid fa-check success-text"></i>
                                    <i v-else class="fa-solid fa-xmark danger-text"></i>
                                </td>
                                <td>
                                    {{ gameweek.entries_count }}
                                </td>
                                <td>
                                    {{ gameweek.active_entries_count }}
                                </td>
                                <td>
                                    {{ gameweek.transfers_count }}
                                </td>
                                <td>
                                    {{ gameweek.chips_count }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeoMeta} from "@unhead/vue";
import {ref, computed, onMounted} from "vue";
import {useDefaultStore} from "@/stores/defaultStore";
import dayjs from "dayjs";
import DateFromNow from "@/components/DateFromNow.vue";

export default {
    components: {DateFromNow},
    setup(props) {
        const defaultStore = useDefaultStore()

        useSeoMeta({
            title: () => 'Mod Status',
            description: () => 'Mod Status Page'
        })

        onMounted(() => {
            axios
                .get('/api/status')
                .then(response => {
                    gameweeks.value = _.groupBy(
                        _.map(response.data.gameweeks, gameweek => {
                            return {
                                ...gameweek,
                                locked_at_text: gameweek.locked_at ? new dayjs(gameweek.locked_at).fromNow(true) : null,
                                open_at_text: gameweek.open_at ? new dayjs(gameweek.open_at).fromNow(true) : null
                            }
                        }),
                    'season_id')

                    smallGameweeks.value = response.data.small_gameweeks

                    modStatusFixtures.value = response.data.mod_status_fixtures

                    duplicateFixtures.value = response.data.duplicate_fixtures

                    gameweeksNoAutoSubs.value = response.data.gameweeks_no_auto_subs
                })
        })

        const seasons = computed(() => _.keyBy(_.sortBy(_.filter(defaultStore.seasons, {status: 'active'}), 'nation_code'), 'id'))
        const gameweeks = ref(undefined);
        const smallGameweeks = ref(undefined);
        const gameweeksNoAutoSubs = ref(undefined);
        const modStatusFixtures = ref(undefined)
        const duplicateFixtures = ref([]);
        const nowTimestamp = dayjs().unix()

        return {
            seasons,
            gameweeks,
            smallGameweeks,
            gameweeksNoAutoSubs,
            modStatusFixtures,
            duplicateFixtures,
            nowTimestamp
        }
    }
}
</script>
