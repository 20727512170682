<template>
    <tr>
        <th>
            <span class="mx-3">{{ gameweek.id }}</span>
        </th>
        <th>
            <span class="mx-3">{{ gameweek.number }}</span>
        </th>
        <td>
            {{ gameweek.status }}
        </td>
        <td>
            {{ startAt }}
        </td>
        <td>
            {{ endAt }}
        </td>
        <td>
            {{ lockedAt }}
        </td>
        <td v-for="numericInput in numericInputs">
            <form v-if="editable" @submit.prevent="saveData">
                <input
                    type="number"
                    v-model="localDraft[numericInput]"
                    @input="emitChanges"
                    :placeholder="numericInput"
                    :class="{ warning: localDraft[numericInput] !== gameweek[numericInput] }"
                />
            </form>
            <span v-else-if="['free_transfers', 'picks_per_team'].includes(numericInput) && gameweek[numericInput] === null">
                Unlimited
            </span>
            <span v-else>
                {{ gameweek[numericInput] }}
            </span>
        </td>
        <td>
            <span v-if="!editable && gameweek.calculated_auto_subs">
                Yes
            </span>
            <button v-else-if="gameweek.calculated_auto_subs" class="btn warning" @click="calculateAutoSubs">
                Redo
            </button>
            <button v-else-if="gameweek.status === 'active' || gameweek.status === 'finished'" class="btn success" @click="calculateAutoSubs">
                Run
            </button>
            <span v-else>No</span>
        </td>
        <td>
            <button class="btn danger" @click="deleteGameweek">
                Delete
            </button>
        </td>
    </tr>
</template>

<script>
import {computed, ref, watch} from "vue";
import { useSeasonStore } from "../stores/seasonStore";
import { useFlashStore } from "@/stores/flashStore";
import dayjs from "dayjs";

export default {
    emits: ['update:modelValue', 'saveRow'],
    props: {
        gameweek: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const numericInputs = ['free_transfers', 'rollover_free_transfers', 'max_free_transfers', 'cost_per_transfer', 'picks_per_team']

        const localDraft = ref({ ...props.modelValue });

        const lockedAt = computed(() => {
            return dayjs(props.gameweek.locked_at).format('YYYY-MM-DD HH:mm');
        })

        const startAt = computed(() => {
            return dayjs(props.gameweek.start_at).format('YYYY-MM-DD HH:mm');
        })

        const endAt = computed(() => {
            return dayjs(props.gameweek.end_at).format('YYYY-MM-DD HH:mm');
        })

        watch(
            () => props.modelValue,
            (newVal) => {
                localDraft.value = { ...newVal }; // Update localDraft if modelValue changes
            }
        );

        const calculateAutoSubs = () => {
            useFlashStore().setFlash('info', 'Calculating auto-subs please wait...')

            axios
                .post('/api/gameweeks/' + props.gameweek.id + '/run-auto-subs')
                .then(response => {
                    useFlashStore().setFlash('success', 'Auto-subs calculated');

                    useSeasonStore().setGameweek(response.data.data)
                })
                .catch(error => {
                    useFlashStore().axiosError(error, 'Auto-subs FAILED');
                })
        }

        const deleteGameweek = () => {
            useFlashStore().setFlash('info', 'Deleting Gameweek...')

            axios
                .delete('/api/gameweeks/' + props.gameweek.id)
                .then(response => {
                    useFlashStore().setFlash('success', 'Gameweek Deleted');

                    useSeasonStore().setGameweeks(response.data.data)
                })
                .catch(error => {
                    useFlashStore().axiosError(error, 'DELETED FAILED');
                })
        }



        const emitChanges = () => {
            emit('update:modelValue', localDraft.value);
        };

        const saveData = () => {
            emit('saveRow', props.gameweek.id); // Emit event to save the row in parent
        };

        return { numericInputs, localDraft, lockedAt, startAt, endAt, calculateAutoSubs, deleteGameweek, emitChanges, saveData };
    }
};
</script>
