<template>
    <transfers-list-players></transfers-list-players>
</template>

<script>
import {useSeoMeta} from "@unhead/vue";
import TransfersListPlayers from "@/components/TransfersListPlayers.vue";

export default {
    components: {TransfersListPlayers},
    setup(props) {
        useSeoMeta({
            title: () => 'God Transfers',
            description: () => 'God Transfers Page'
        })
    }
}
</script>
