<template>
    <div v-if="players && players.data.length > 0">
        <slot name="header"></slot>
        <div class="pagination-controls blue-gradient p-2 rounded flex items-center">
            <div>
                <select @input="setSeason">
                    <option :value="null">
                        All Seasons
                    </option>
                    <option v-for="season in seasons" :value="season.id">
                        {{ season.name }}
                    </option>
                </select>
            </div>
            <simple-pagination :collection="players" @goTo="getPage($event)"></simple-pagination>
            <button @click="getPage(1)" class="btn">
                Refresh
            </button>
            <div class="ml-3">
                {{ players.meta.total }} Total
            </div>
        </div>
        <div v-if="players" class="transfers-table stats-table">
            <table class="w-full">
                <thead>
                <tr>
                    <th>
                        Seasons
                    </th>
                    <th>
                        Player
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        From
                    </th>
                    <th>
                        To
                    </th>
                    <th>
                        Fee
                    </th>
                    <th>
                        Source
                    </th>
                </tr>
                </thead>
                <tbody>
                <transfer-list-player-row
                    v-for="player in players.data"
                    :player="player"
                    :transfers="player.platform_transfers"
                    :player-seasons="player.player_seasons"
                ></transfer-list-player-row>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import SimplePagination from "@/components/SimplePagination.vue";
import TransferListPlayerRow from "@/components/TransferListPlayerRow.vue";
import {useDefaultStore} from "@/stores/defaultStore";

export default {
    components: {TransferListPlayerRow, SimplePagination},
    setup(props) {
        const defaultStore = useDefaultStore()

        onMounted(async () => {
            getPage(1)
        })

        const getPage = function (pageNum) {
            players.value = null
            axios
                .get('/api/transfers/pending', {
                    params: {
                        page: pageNum,
                        season_id: activeSeasonId.value
                    }
                })
                .then(response => {
                    players.value = response.data
                })
        }

        const players = ref(null)

        const seasons = computed(() => {
            return _.sortBy(_.pickBy(defaultStore.seasons, {status:'active'}), 'name')
        })

        const activeSeasonId = ref(null)

        const setSeason = (event) => {
            activeSeasonId.value = event.target.value

            getPage(1)
        }

        return {players, getPage, seasons, setSeason}
    }
}
</script>
