<template>
    <div>
        <div class="mx-3 mb-3 flex items-center">
            <button @click="saveAll" class="btn success">Save All</button>
            <p class="ml-3">Press enter on any input to save that row. Or click "Save All" to save all rows. Yellow background indicates data is not saved. All dates and times are in your local time.</p>
        </div>
        <table class="form-table">
            <thead>
            <tr>
                <th>ID</th>
                <th>GW</th>
                <th>Status</th>
                <th>Date <small>{{ userTimezone }}</small></th>
                <th>Home Team</th>
                <th>Home Goals</th>
                <th>Away Team</th>
                <th>Away Goals</th>
                <th>Edit</th>
            </tr>
            </thead>
            <tbody>
            <mod-fixture-row
                v-for="fixture in fixtures"
                :key="fixture.id"
                :fixture="fixture"
                :user-timezone="userTimezone"
                v-model="draft[fixture.id]"
                @saveRow="saveRow"
            ></mod-fixture-row>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useSeoMeta } from "@unhead/vue";
import { useSeasonStore } from "@/stores/seasonStore";
import { useFlashStore} from "@/stores/flashStore";
import {computed, onMounted, ref} from "vue";
import ModFixtureRow from "@/components/ModFixtureRow.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    components: {ModFixtureRow},
    props: {
        seasonId: {
            type: [Number, String],
            required: true
        }
    },
    setup() {
        const seasonStore = useSeasonStore();
        const flashStore = useFlashStore();

        useSeoMeta({
            title: () => 'Mod Fixtures | ' + seasonStore.season.name,
            description: () => 'Mod Fixtures ' + seasonStore.season.name
        });

        const fixtures = computed(() => {
            return _.sortBy(seasonStore.fixtures, 'timestamp');
        });

        const draft = ref({});

        const userTimezone = ref(dayjs.tz.guess());

        onMounted(() => {
            setDraft()
        })

        const setDraft = function() {
            draft.value = _.mapValues(
                seasonStore.fixtures,
                fixture => {
                    return {
                        ...fixture,
                        local_kickoff: dayjs.utc(fixture.kickoff).tz(userTimezone.value).format('YYYY-MM-DDTHH:mm'),
                    }
                }
            )
        }

        const saveRow = (fixtureId) => {
            draft.value[fixtureId].kickoff = dayjs(draft.value[fixtureId].local_kickoff).utc().format('YYYY-MM-DD HH:mm:ss');
            draft.value[fixtureId].timestamp = dayjs(draft.value[fixtureId].local_kickoff).utc().unix();

            seasonStore.updateFixture(fixtureId, draft.value[fixtureId])
                .then(() => {
                    flashStore.setFlash('success', 'Fixture Updated')

                    setDraft()
                })
                .catch(error => flashStore.axiosError(error, 'Failed to update fixture'));
        };

        const saveAll = () => {
            _.forEach(draft.value, (fixture) => {
                fixture.kickoff = dayjs(fixture.local_kickoff).utc().format('YYYY-MM-DD HH:mm:ss');
                fixture.timestamp = dayjs(fixture.local_kickoff).utc().unix();
            });

            seasonStore.updateFixtures(draft.value)
                .then(() => {
                    flashStore.setFlash('success', 'All Fixtures Updated')

                    setDraft()
                })
                .catch(error => flashStore.axiosError(error, 'Failed to update fixtures'));
        };

        return { fixtures, draft, userTimezone, saveAll, saveRow };
    }
};
</script>
