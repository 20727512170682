export default {
    async get(seasonId, userId) {
        const response = await axios.get('/api/user-submissions', {
            params: {
                season_id: seasonId,
                user_id: userId
            }
        })

        return response.data
    },
    async create(playerSeasonId, payload) {
        const response = await axios.patch('/api/user-submissions', {
            player_season_id: playerSeasonId,
            ...payload
        })

        return response.data
    }
}
