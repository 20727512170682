<template>
    <tr :class="{'auth-entry-row': entry.id === authEntryId}">
        <td v-if="showAdmin">
            <a href="#" class="text-red-500" @click.prevent="$emit('removeEntry', entry)">Remove</a>
            <div v-if="entry.status === 'invalid'" class="text-yellow-500">
                Team is invalid
            </div>
        </td>
        <td>
            #{{ rank }}
        </td>
        <th>
            <router-link :to="{name: 'points', params: {entryId: entry.id, seasonId: entry.season_id}}">
                {{ entry.name }}
            </router-link>
        </th>
        <td v-if="showGameweeks">
            {{ 'GW ' + entry.starting_gameweek_number }}
        </td>
        <td>
            {{ entry.points }}
        </td>
        <template v-if="showGameweeks">
            <template v-for="gameweek in gameweeks">
                <td v-if="activeGw === gameweek.id" class="active">
                    #{{ gwRanks[gameweek.id] || '-' }}
                </td>
                <td :title="'GW Rank ' + gwRanks[gameweek.id]">
                    {{ gwPoints[gameweek.id] || '-' }}
                </td>
            </template>
        </template>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import dayjs from 'dayjs';

export default {
    emits: ['removeEntry'],
    props: {
        entry: {
            type: Object,
            required: true
        },
        showGameweeks: {
            type: Boolean,
            default: false
        },
        showAdmin: {
            type: Boolean,
            require: true
        },
        month: {
            type: String,
            default: 'overall'
        },
        activeGw: {
            type: Number,
            default: null
        },
        gameweeks: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const authEntryId = computed(() => {
            return store.authEntry ? store.authEntry.id : null
        })

        const gwPoints = computed(() => {
            return _.pluck(props.entry.gameweeks, 'points', 'gameweek_id')
        })

        const gwRanks = computed(() => {
            return _.pluck(props.entry.gameweeks, 'rank', 'gameweek_id')
        })

        const total = computed(() => {
            return entry.points
        })

        const rank = computed(() => {
            return _.get(props.entry, 'pivot_rank') || _.get(props.entry, 'rank')
        })

        return {authEntryId, gwPoints, gwRanks, total, rank}
    }
}
</script>
