<template>
    <div v-if="player" :key="player.id">
        <div class="pb-3 px-3 header flex items-center">
            <h1 class="m-0">{{ player.name }} #{{ player.id }}</h1>
            <div>
                <router-link
                    :to="{name: 'admin'}"
                    class="btn"
                >
                    Back to admin
                </router-link>
            </div>
        </div>
        <div class="p-3">
            <player-seasons-stats :player-seasons="[player]"></player-seasons-stats>
        </div>
        <div class="p-3">
            <player-transfers-list
                route-name="admin-player"
                :player="player"
                header="Transfers"
            ></player-transfers-list>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2">
            <div>
                <player-edit-form
                    :player="player"
                ></player-edit-form>
                <div class="box green-gradient">
                    <h1>Matches</h1>
                    <player-result-table
                        :player-gameweeks="playerGameweeks"
                        :player-fixtures="playerFixtures"
                    ></player-result-table>
                </div>
            </div>
            <div>
                <player-merge-form
                    :player="player"
                ></player-merge-form>
                <player-historical-stats
                    class="blue-gradient"
                    :player="player"
                ></player-historical-stats>
                <div class="mx-3">
                    <div class="flex items-center">
                        <div class="header">
                            <h1>Similar Players</h1>
                        </div>
                        <p class="ml-3">{{ player.position }} players from {{ team.name }}</p>
                    </div>
                    <admin-player-table
                        :players="similarPlayers"
                        :columns="['name', 'age', 'position', 'value', 'appearances', 'points']"
                    ></admin-player-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref, onMounted} from "vue";
import PlayerMergeForm from "@/components/PlayerMergeForm.vue";
import PlayerEditForm from "@/components/PlayerEditForm.vue";
import PlayerSeasonsStats from "@/components/PlayerSeasonsStats.vue";
import AdminPlayerTable from "@/components/AdminPlayerTable.vue";
import PlayerResultTable from "@/components/PlayerResultTable.vue";
import TransfersList from "@/components/TransfersList.vue";
import {useSeoMeta} from "@unhead/vue";
import PlayerHistoricalStats from "@/components/PlayerHistoricalStats.vue";
import PlayerTransfersList from "@/components/PlayerTransfersList.vue";

export default {
    components: {
        PlayerTransfersList,
        PlayerHistoricalStats,
        TransfersList,
        PlayerResultTable, AdminPlayerTable, PlayerSeasonsStats, PlayerEditForm, PlayerMergeForm},
    props: {
        playerId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()
        const player = computed(() => {
            return store.players[props.playerId]
        })

        useSeoMeta({
            title: () => player.value.name,
            description: () => player.value.name
        })

        const team = computed(() => {
            return store.teams[player.value.team_id]
        })

        const similarPlayers = computed(() => {
            return _.orderBy(
                _.filter(
                    store.players,
                    p => {
                        return p.id !== player.value.id
                            && p.position === player.value.position
                            && p.team_id === player.value.team_id
                    }
                ),
                'value',
                'desc'
            )
        })

        const playerGameweeks = ref([])
        const playerFixtures = ref([])

        onMounted(async () => {
            await axios
                .get('/api/player-fixtures/' + props.playerId)
                .then(response => {
                    playerGameweeks.value = _.orderBy(response.data.player_gameweeks, 'gameweek_id', 'desc')
                    playerFixtures.value = response.data.player_fixtures
                })
        })

        return {player, team, similarPlayers, playerGameweeks, playerFixtures}
    }
}
</script>
