<template>
    <tr v-for="(transfer, index) in transfers">
        <td v-if="playerSeasons && index === 0" :rowspan="transfers.length">
            <template v-for="playerSeason in playerSeasons">
                <div v-if="seasons.hasOwnProperty(playerSeason.season_id)" class="my-1">
                    <router-link class="hover-underline" :to="{name: routeName, params: {seasonId: playerSeason.season_id, playerId: playerSeason.id}}">
                        {{ seasons[playerSeason.season_id].name }}
                    </router-link>
                    <small> ({{ playerSeason.status }})</small>
                </div>
            </template>
        </td>
        <td>
            {{ player.name }}
        </td>
        <td>
            {{ transfer.date }}
        </td>
        <td>
            {{ transfer.from_name }}
        </td>
        <td>
            {{ transfer.to_name }}
        </td>
        <td>
            {{ transfer.transfer_fee_description }}
        </td>
        <td>
            <a v-if="player.platform_url" target="_blank" :href="player.platform_url">
                Source
            </a>
        </td>
    </tr>
</template>

<script>
import {computed} from "vue";
import {useDefaultStore} from "@/stores/defaultStore";

export default {
    props: {
        player: {
            type: Object,
            required: true
        },
        transfers: {
            type: Array,
            required: true
        },
        playerSeasons: {
            type: Array,
            required: false
        },
        routeName: {
            type: String,
            default: 'mod-player'
        }
    },
    setup(props) {
        const defaultStore = useDefaultStore()

        const seasons = computed(() => {
            return _.pickBy(defaultStore.seasons, {status:'active'})
        })

        return {seasons}
    }
}
</script>
