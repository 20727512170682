<template>
    <nav id="header" :class="{open: showNav}" class="season-mod-nav-bar">
        <h1>
            Moderator Tools
        </h1>
        <button @click="showNav = !showNav" type="button">
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
        <div id="top-nav" class="w-full xl:block xl:w-auto">
            <ul>
                <li>
                    <router-link :to="{name:'mod-season'}">
                        Players
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'mod-create-player'}">
                        Create Player
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'mod-teams'}">
                        Teams
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'mod-gameweeks'}">
                        Gameweeks
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'mod-fixtures'}">
                        Fixtures
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
import { useDefaultStore } from '../stores/defaultStore'
import {computed, ref} from 'vue'

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        seasonId: {
            type: Number,
            required: false
        }
    },
    setup() {
        const store = useDefaultStore()

        const authUser = computed(() => {
            return store.authUser;
        })

        const showNav = ref(false)

        const discordLink = computed(() => {
            return store.discordLink
        })

        const defaultTitle = computed(() => {
            return store.title
        })

        return {defaultTitle, authUser, showNav, discordLink}
    }
}
</script>
