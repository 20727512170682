<template>
    <template v-if="season">
        <div class="green-gradient p-5 flex justify-center">
            <div style="max-width:1200px">
                <div class="flex">
                    <img v-if="season.flag_path" :src="season.flag_path" class="mr-3" />
                    <h1 class="text-4xl">
                        {{ season.seo_title }}
                    </h1>
                </div>
                <p class="text-xl mt-2">
                    {{ season.seo_description }}
                </p>
            </div>
        </div>
        <div class="red-gradient p-5 flex justify-center">
            <div class="grid grid-cols-1 xl:grid-cols-[35%_65%] gap-4" >

                <div class="xl:block">
                    <div>
                        <img v-if="season.image_path" :src="season.image_path"  style="margin:0 auto;" />
                    </div>
                    <div class="grid grid-cols-6">
                        <div v-for="team in teams" class="pt-6 px-3">
                            <img v-if="team.image_path" :src="team.image_path" width="75" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Advanced Stats</h2>
                            <p class="text-xl mb-2">
                                <template v-if="season.scoring_type === 'advanced'">
                                    It's not just goals and clean sheets that earn you points, our scoring system uses key passes, shots on target, duels won, clearances, blocks, interceptions and more.
                                </template>
                                <template v-else>
                                    Your team scores points whenever your players get minutes, goals, assists and clean sheets in real life matches.
                                </template>
                            </p>
                        </div>
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Transfer Market</h2>
                            <p class="text-xl mb-2">
                                <template v-if="season.budget">
                                    The best players cost the most, but breakout stars can be found for a bargain. Daily price changes give you the chance to get an edge over your opponents.
                                </template>
                                <template v-else>
                                    There is no player pricing in this fantasy league, pick whoever you like, but watch out, the star names won't be the top scorers every week.
                                </template>
                            </p>
                        </div>
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Mini Leagues</h2>
                            <p class="text-xl mb-2">
                                Compete against the world in our global league or setup mini leagues with your friends, family or colleagues.
                            </p>
                        </div>
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Mini Games</h2>
                            <p class="text-xl mb-2">
                                We also have a Prediction League and a fixture specific blackjack/pontoon style game called 21.
                            </p>
                        </div>
                    </div>
                    <div v-if="season.status === 'active'" class="mt-3">
                        <router-link :to="signUpRoute" class="btn" style="font-size:3rem;padding:.7rem 1rem">
                            Play Now
                        </router-link>
                        <p class="p-2">It's completely FREE to play.</p>
                    </div>
                    <div v-else class="mt-3">
                        <router-link :to="signUpRoute" class="btn" style="font-size:3rem;padding:.7rem 1rem">
                            Sign Up
                        </router-link>
                        <p class="p-2">This league hasn't started yet. Sign up now to get an email when you can start picking your squad.</p>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="season">
            <div class="p-3">
                Below you can see a preview of the players, <router-link :to="signUpRoute" class="underline">start playing</router-link> to pick your team for real.
            </div>
            <player-list></player-list>
        </template>
    </template>
</template>

<script>
import {useDefaultStore} from "@/stores/defaultStore";
import {useSeasonStore} from "@/stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import Fixtures from "../components/Fixtures.vue";
import Standings from "../components/Standings.vue";
import BetaText from "../components/BetaText.vue";
import TeamOfTheWeek from "../components/TeamOfTheWeek.vue";
import TransferStats from "../components/TransferStats.vue";
import SelectionStats from "../components/SelectionStats.vue";
import Explanation from "../components/Explanation.vue";
import PriceChangeStats from "../components/PriceChangeStats.vue";
import Gameweeks from "@/components/Gameweeks.vue";
import {useSeoMeta} from "@unhead/vue";
import OverallLeaderboard from "@/components/OverallLeaderboard.vue";
import EntryLeaderboard from "@/components/EntryLeaderboard.vue";
import PlayerList from "@/components/PlayerList.vue";
import NavBar from "@/components/NavBar.vue";

export default {
    components: {
        NavBar,
        PlayerList,
        EntryLeaderboard,
        OverallLeaderboard,
        Gameweeks,
        PriceChangeStats,
        Explanation,
        SelectionStats,
        TransferStats,
        TeamOfTheWeek,
        BetaText,
        Standings,
        Fixtures
    },
    setup(props) {
        const store = useDefaultStore()
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => seasonStore.season.seo_title,
            description: () => seasonStore.season.seo_description
        })

        const signUpRoute = computed(() => {
            return store.authUser
                ? {name: 'transfers',params: {seasonId: season.value.id}}
                : {name: 'auth'}
        })

        const season = computed(() => seasonStore.season)

        const teams = computed(() => seasonStore.teams)

        return {season, teams, signUpRoute}
    }
}
</script>
